<section class="dinamic-list">
  <div class="container">

    <div class="row d-flex justify-content-center">

      <div class="col-md-6">

        <ul class="list-group">

          <ng-container *ngFor="let item of receiveArray">

            <li class="list-group-item" *ngIf="item.dinamic_names.length > 2 "> {{ item.dinamic_names }} </li>

          </ng-container>

        </ul>

      </div>

    </div>

  </div>

  <div class="container mt-4">

    <div class="row">
      <div class="col-md-12 d-flex justify-content-center mb-2">

        <button class="btn btn-success btn-lg" (click)="enableForm(true, false)"> Voltar ao formulário </button>

      </div>
    </div>

  </div>
</section>