<div class="jumbotron">
  <div class="container text-center">
    <div class="row">
      <div class="col-12">
        <h1 class="display-5">Aprenda como fazer FormArray com Angular</h1>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <p class="mt-3">Neste projeto tem de forma fácil e prática de como se trabalhar com o FormArray do angular e vem
          com
          um bônus que é um dos maiores impecilhos de quem está inciando a sua carreira como Front-End Angular,
          que é nada mais nada menos que o nosso queridissímo e salva vidas EventEmitter ( input e output ).</p>
        <p><button class="btn btn-primary" role="button" (click)="openForm([true,false])">Click aqui para testar</button></p>
      </div>
    </div>
  </div>
</div>

<app-dinamic-form *ngIf="formActive" (sendArray)="getNames($event)" (disabledForm)="openForm($event)">
</app-dinamic-form>

<app-list-dinamic-form *ngIf="listActive" [receiveArray]="sendArray" (disabledForm)="openForm($event)">
</app-list-dinamic-form>