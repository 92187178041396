<section class="dinamic-forms">
  <div class="container">

    <form [formGroup]="dinamicForms">

      <div *ngFor="let dinamic of d.controls; let i = index;">

        <div [formGroup]="dinamic" class="row d-flex justify-content-center">

          <div class="col-md-8  d-flex justify-content-center mb-2">
            <input type="text" class="form-control form-control-lg" formControlName="dinamic_names">
            <button class="btn btn-add-remove btn-danger" (click)="removeField(i)" *ngIf="i != 0">-</button>
            <button class="btn btn-add-remove btn-success" (click)="addField()">+</button>
          </div>

        </div>

      </div>

    </form>

  </div>

  <div class="container mt-4">

    <div class="row">
      <div class="col-md-12 d-flex justify-content-center mb-2">

        <button class="btn btn-success btn-lg" (click)="envForm(this.d.value)" [disabled]="!dinamicForms.valid"> Enviar nomes </button>

      </div>
    </div>

  </div>
</section>